import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,Tooltip,IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CreateIcon from '@material-ui/icons/Create';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '60rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        padding:'1rem',
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '58rem',
      minHeight: window.innerHeight-190,
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '58rem',
        maxHeight: window.innerHeight-240,
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[              
    {
      id:1, titulo:"Tipo", alinear:'center'
    },     
    {
      id:2, titulo:"Nombre", alinear:'left'
    }, 
    {
      id:5, titulo:"Descripción", alinear:'left'
    }, 
    {
      id:3, titulo:"Preguntas", alinear:'center'
    },
    {
      id:4, titulo:"Fecha", alinear:'center'
    },     
        
  ];
  const arreglo=[              
    {
      id:1, Nombre:"Encuesta 1", Descr:"Formato sencillo",Preguntas:4,fecha:"10-04-2023"
    },     
    {
      id:2, Nombre:"Encuesta 2", Descr:"Formato complejo",Preguntas:3,fecha:"12-04-2023"
    }, 
    {
      id:3, Nombre:"Encuesta 3", Descr:"Formato sencillo",Preguntas:3,fecha:"14-04-2023"
    },
    {
      id:4, Nombre:"Encuesta 4", Descr:"Formato complejo",Preguntas:8,fecha:"12-04-2023"
    },     
        
  ];
const alturaCont = window.innerHeight<937 ? window.innerHeight-180 : window.innerHeight-200

export default function TablaEncuesta({muni,}){    
    const classes = useStyles();    
    const [promotor, setPromotor] = useState({nombre:""})
    const [seleccion, setSeleccion] = useState(null)
    const [datos,setDatos] = useState([]);     
    const [modalNvo, setModalNvo] = useState(false);  
    const [modalFoto, setModalFoto] = useState(false);  

    const auxCol=process.env.REACT_APP_Fondo_Color
    const {nombre} = promotor;    
    useEffect(()=>{

        setDatos(arreglo)

        //la siguiente linea puede generar errores o no en el useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[muni])          

    const onChangeTable =(regis)=>{                       
        setSeleccion(regis)
    }

    function filtro(){        
      let auxCadena=nombre.trim();       
      let info=[];                        
      let aux=  datos.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
      info=aux 
      let auxNum=0;
        if (aux!==null||aux!=='') 
        {
        aux.forEach((mun)=>
        { if (mun.nombreReg!=='PUEBLA')
          {   
            auxNum++;
          }                
        })
      }         
      return TablaEstr(info);
    }

    const celda = (alinear, estilo, contenido,index) => {
        return (
        <TableCell align={alinear} key={index} className={classes.tableCell} 
            padding={'none'} style={estilo} >
            {contenido}
        </TableCell>)
    }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >    
          <TableRow>  
            <CelTitl index={5} titulo={auxDatos.length} />            
            {columnas.map((columna,index)=>              
              <CelTitl index={index+5} titulo={columna.titulo} aline={columna.alinear} />                          
            )}                                       
          </TableRow>                        
        </TableHead>  
        <TableBody>                                  
        {auxDatos.map((elemnto,index) => {  
          const auxVal=seleccion?.id === elemnto.id ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index} onClick={ () => onChangeTable(elemnto) }  
          >   
            {celda("center", {width:"2rem"}, 
              elemnto.Fotos!==0?<Tooltip title={`Galeria `}>
              <IconButton aria-label="agregar nuevo" onClick={() => setModalFoto(true)} 
                component="span" size="small" 
              >
                <PhotoLibraryIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
              </IconButton>
            </Tooltip> :null
            ,index+1) }   
            {celda("center", {width:"2rem"}, elemnto.id,index+5) }                                 
            {celda("left", {width:"15rem"}, elemnto.Nombre ,index+6) } 
            {celda("left", {width:"15rem"}, elemnto.Descr ,index+6) } 
            {celda("center", {fontSize:"1rem",width:"2rem"},`${elemnto.Preguntas } `,index+7) }            
            {celda("center", {width:"10rem"}, elemnto.fecha,index+8) }                                                                 
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
                   
  const contenido = nombre.length > 0 ? (filtro()):(TablaEstr(datos));    
  const llamada= datos.length !== 0 ?(contenido):(<Box style={{paddingTop:1+'rem'}}><Typography variant="h4" component="h3">No contiene datos</Typography></Box>)
                                                                                 
                                                                                                          
  return (
  <Box display={'flex'} flexDirection="row" style={{height: alturaCont}}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-210}}>                        
      {llamada}   
    </Card>
   
  </Box>                    
  );
}