import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState,useRef} from "react";
import {FormControl, Grid, Button, InputLabel, MenuItem, Box, 
        TextField,FormLabel,RadioGroup,Radio,FormControlLabel,
        ImageList, ImageListItem, ImageListItemBar,IconButton,
        Select,
        Typography,
        } from "@material-ui/core";
import qs from "qs";
import {Autocomplete, Alert} from "@material-ui/lab/";
import {ErrorGeneral, llamaApiCargaToken,llamaApiFormDat3,llamadaApiToken} from "../funciones/ClienteAxios";
import {v4 as uuidv4} from 'uuid';
import {toast} from "react-toastify";
import axios from "axios";
import {useHistory} from "react-router-dom";
import { obtenDispo } from '../funciones/IdDispositivo';
import CloseIcon from "@material-ui/icons/Close";
import Progress from "../generales/Progress";
import SelectList from '../generales/SelectList';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    }, imageList: {
        maxHeight: 450, maxWidth: 500
    }, icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    }, "@global": {
        '#form-file-upload': {
            textAlign: 'center', position: 'relative', width: 550
        }, '#input-file-upload': {
            display: 'none',
        // }, '#label-file-upload': {
        //     height: '100%',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     borderWidth: '2px',
        //     borderRadius: '1rem',
        //     borderStyle: 'dashed',
        //     borderColor: theme.palette.secondary.main,
        //     paddingTop: 25,
        //     paddingBottom: 25
        },
        'upload-button': {
            cursor: 'pointer',
            padding: '0.25rem',
            fontSize: '1rem',
            border: 'none',
            fontFamily: 'Oswald',
            backgroundColor: 'transparent'
        }, 'upload-button:hover': {
            textDecorationLine: 'underline'
        }, '#label-file-upload.drag-active': {
            backgroundColor:process.env.REACT_APP_Color_Second, color: 'white'
        }, '#drag-file-element': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: '1rem',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
}));

const listTpDet=[{id:1, detalle:"ALL",cadena:"Todos"},
                 {id:2, detalle:"REG",cadena:"Circunscripción"},
                 {id:3, detalle:"EDO",cadena:"Estado"},
                 {id:4, detalle:"FED",cadena:"Dto Federal"},
                 {id:5, detalle:"LOC",cadena:"Dto Local"},
                 {id:6, detalle:"MUN",cadena:"Municipio"},
                 {id:7, detalle:"SEC",cadena:"Sección"},
                 {id:8, detalle:"PRO",cadena:"Promotor"},
                 {id:9, detalle:"ORG",cadena:"Organización"},
                 {id:10, detalle:"SUB",cadena:"Usuario Especifico"},]

const listIcono=[ {id:1, dir:"informacion.svg", cadena:"Informativo",info:"INFO"},
{id:3, dir:"warning.svg", cadena:"Pendiente",info:"WARNING"},
,{id:2, dir:"urgent.svg", cadena:"Urgente",info:"URGENT"},
]                    
const ModalNvoEdit = ({abrirModal, setAbrirModal,  actualiza, titulo,  listEstruc, setLastNotif}) => {
    const history = useHistory();
    const classes = useStyles();      
    const source = axios.CancelToken.source();    
    const idusu = localStorage.getItem("UsuId");
    const inputRef = useRef(null);    
    const formData = new FormData();
    const auxDispV =obtenDispo()  
    const [espera,setEspera]= useState(false);    
    const [dragActive] = useState(false);
    const [nvoRegis, setNvoRegis] = useState({ Superior:"", nomNotif:"",icon:"", descr:"",link:"",tipo:"", tpEstructura:""});
    const { Superior, nomNotif, icon, descr, link, tipo, tpEstructura, } = nvoRegis
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [size, setSize] = useState(0);
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);    
    const [arraySubidos, setArraySubidos] = useState([])
    const [arrayPreview, setArrayPreview] = useState([])
    const[listSuperior,setListSuperior]= useState([]);   
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    const [leyenda, setLeyenda] = useState("");

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    useEffect(() => {       
        if (tipo!=="") {            
            const filtrado = listTpDet.filter((tpDet)=>tpDet.id=== tipo)
            const msng=`Se mandara la notificación a ${filtrado[0].cadena}`
            setLeyenda(msng)
        }else{
            setLeyenda("")
        }
    }, [tipo]);    

    useEffect(() => {          
      if (tpEstructura!=="") {
        getListSuperior(tpEstructura) 
      }
    }, [tpEstructura])
    
    function getListSuperior(tipo)  {            
        const url= `usuario/v2/estructura-list-xtipo`;                            
        const data = qs.stringify({                
            usuario:idusu,           
            tipo:tipo,               
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {                                   
                let filtro=[]
                aux.forEach(element => {
                    if (element.Id>0 ) {
                        filtro.push(element)
                    }
                });
                setListSuperior(filtro)
            }     
            else{
                setListSuperior([])
            }                                                                 
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };
 
    const onChangeTipo = (e) => {
        if (e !== null && e !== undefined) {
            setNvoRegis({...nvoRegis, tipo: e.target.value, tpEstructura:"",Superior:""})            
            setMensaje('');
            setAviso(false);
        } else {
            setNvoRegis({ ...nvoRegis, tipo: "",tpEstructura:"",Superior:""})
        }
    }    

    const valGen=(e, expNopermitida,expMenos,expMas,expCadena)=>{
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
        || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
    }

    const valGen2=(e, expNopermitida,expMenos,expMas,expCadena)=>{
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
        || e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
    }

    const onChange = e =>
	{   const expNopermitida = new RegExp('[@$#._:!%-({})/*=?|]');
        //console.log(e.target.value,e.target.name);        
        //console.log("antes del colsol"); 
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9.#_]');                 
        valGen(e, expNopermitida,expMenos,expMas,expCadena)
	};

    const onChangeCorreo = e =>
	{   const expNopermitida = new RegExp('[$#:!%-({})/*=?|]');
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9.@_]');  
        valGen(e, expNopermitida,expMenos,expMas,expCadena)        
	};

    const onChangeAll = e =>
	{   setNvoRegis({ ...nvoRegis, [e.target.name] : e.target.value })    
	};

    const onChangeTxt = e =>
	{   const expNopermitida = new RegExp('[@$#._:!%-({})/*=?|]');
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9]');  
        valGen(e, expNopermitida,expMenos,expMas,expCadena)        
	};

    const onChangeTxt2 = e =>
	{   const expNopermitida = new RegExp('[@$#._:!%-({})/*=?|]');
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9]');  
        valGen2(e, expNopermitida,expMenos,expMas,expCadena)        
	};
    
    const handleChange = function (event) {
        const regex = /([a-zA-Z0-9\s_.\-():])+(.jpg|.jpeg|png)$/
        let image = event.target.files[0]
        let auxCode = uuidv4();
        if (image && image.name.toLowerCase().match(regex)) {
            let auxNombre = auxCode + image.name.slice(image.name.lastIndexOf("."), image.name.length);
            let myNewFile = new File([image], auxNombre, {type: image.type});
            setArraySubidos([...arraySubidos, myNewFile])
            setArrayPreview([...arrayPreview, URL.createObjectURL(image)])
        } else {
            let nombre = "";
            if (image.name.length >= 25) {
                nombre = image.name.substring(0, 24) + "..." + image.name.substring(image.name.lastIndexOf("."), image.name.length);
            } else {
                nombre = image.name;
            }
            let mensaje = `Formato de archivo incorrecto en ${nombre} seleccione una imagen tipo jpg,jpeg o png.`
            toast.warning(mensaje, {
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `incorrecto-sel`
            });
        }
    }
    
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const removeElement = (index) => {
        let newArr = arraySubidos.filter(element => element !== arraySubidos[index]);
        let newArr2 = arrayPreview.filter(element => element !== arrayPreview[index]);
        setArraySubidos(newArr)
        setArrayPreview(newArr2)
    };
    
    const setNotificacion = () => {

       const filtrado=listIcono.filter((icono)=>`${icono.id}`===icon)
       const detFilter=listTpDet.filter((det)=>det.id===tipo)
       //console.log(detFilter);
        const data = qs.stringify({                                    
            usuario:idusu,
            status:1,
            titulo:nomNotif,
            icon:filtrado[0].info,
            mensaje:descr,
            link:link,
            detalle:detFilter[0].detalle,
            idBase:tipo === 10 ? Superior.Id : 0,
            idDispositivo:auxDispV
        });
        const url = "notificacion/v1/new";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                const mensaje ='Notificacion agregada correctamente'
                console.log(response.mensaje);
                setLastNotif(parseInt(response.mensaje))
                toast.success(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `nuevo-notificacion${response.mensaje}`
                })
                actualiza()
                setAbrirModal(false)
            } else {
                setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-notificacion${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const setNotificacionArch = () => {
        const filtrado=listIcono.filter((icono)=>`${icono.id}`===icon)
        const detFilter=listTpDet.filter((det)=>det.id===tipo)
        setBotonDeshabilitado(true)        
        formData.append('usuario', idusu)                    
        formData.append('status', 1)                    
        formData.append('titulo', nomNotif)    
        formData.append('icon', filtrado[0].info)  
        formData.append('mensaje', descr)  
        formData.append('link', link)  
        formData.append('detalle', detFilter[0].detalle)  
        formData.append('idBase', tipo === 10 ? Superior.Id : 0)   
        formData.append('idDispositivo', auxDispV)
        arraySubidos.forEach((img) => {
            formData.append('multi-files', img)
        })

        const url = 'notificacion/v1/new-file';

        function respuesta(response) {
            if (response.success === true||response.respuesta === 1 ) {
                setProgresoEnvio(0)
                setAviso(true)        
                setBotonDeshabilitado(true)
                toast.success('Notificacion enviada e imagen de evidencia agregada correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-evidencia${1}`
                })
                setAbrirModal(false)
                actualiza()
            } else {
                setBotonDeshabilitado(false);
                setEspera(false)
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-recorrido${1}`
                })
                setAbrirModal(false)
            }
        }

        const mensajeError=()=>{
            toast.error('Ha ocurrrido un error, intente mas tarde', {
                pauseOnFocusLoss: false,
                toastId: `error-notifi${2}`
            })
        }

        const headers={size:size, clase:'NOTIFICACION', tipo: 'SPACE', }
        llamaApiFormDat3(formData, url, respuesta, ErrorGeneral, setEspera, history,source, 
            headers,setProgresoEnvio,mensajeError)                   
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
            //console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    function guardarDatos(e) {
        e.preventDefault()
        if (!verificaNulos(icon,"Prioridad")
            && !verificaNulos(tipo,"Tipo") 
            && ((tipo===10 && !verificaNulos(tpEstructura,"Estructura") ) || tipo!==10  )
            && ((tipo===10 && !verificaNulos(Superior,"Superior") ) || tipo!==10 )// || listSuperior.length===0)
            && !verificaNulos(nomNotif,"Titulo") 
            && !verificaNulos(descr,"Descripción")) 
        {   
            setAviso(false);
            if (arrayPreview.length !== 0) {
                setNotificacionArch()
            }
            else
            {
                setNotificacion()
            }
            
        } 
    }

    const onChangeRad=(e)=>{
        setNvoRegis({...nvoRegis,[e.target.name] : e.target.value})                    
    }

    function uploadFiles() {
        return <Grid item >
            <form id="form-file-upload">
            <input ref={inputRef} type="file" accept="image/*" id="input-file-upload"// multiple
                onChange={handleChange}/>
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                {arraySubidos.length < 5 ? 
                <div>
                    {/*<p>Arrastra y suelta tus archivos o</p>*/}
                    <Button color={'primary'} variant={'contained'} className="upload-button"
                        disabled={espera ? true : false}
                        onClick={onButtonClick}>
                        BUSCAR ARCHIVOS
                    </Button>
                </div> 
                : null}
            </label>
            </form>
        </Grid>
    }

    function showImages() {
        return <Grid item xs={12}>
            <ImageList className={classes.imageList}>
                {arraySubidos.map((imagen, index) => <ImageListItem key={index}>
                    <img src={arrayPreview[index]} alt={imagen.name}/>
                    <ImageListItemBar
                        title={imagen.name}
                        actionIcon={<IconButton aria-label={`${imagen.name}`}
                                                className={classes.icon} onClick={() => removeElement(index)}>
                            <CloseIcon/>
                        </IconButton>}
                    />
                </ImageListItem>)}
            </ImageList>
        </Grid>
    }

    const obtenIcono=(cadena)=>{
        let icono="./iconos/"+cadena        
        return icono
    }
    
    const onChangeSlct=(e)=>{    
        e.preventDefault()
         if (e!== null && e!== undefined) 
        {   setNvoRegis({...nvoRegis,[e.target.name]:e.target.value})   
        }
        else
        {   setNvoRegis({...nvoRegis,[e.target.name]:""})          
        }        
    }

    const guardarSupr=(e)=>{            
        if (e!== null && e!== undefined) 
        {   setNvoRegis({...nvoRegis, Superior : e  })      
            setMensaje('');                
            setAviso(false); 
        }
        else
        {   setNvoRegis({...nvoRegis, Superior : "" })                   
        }        
    }

    return (
    <Modal
        titulo={titulo} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
            <FormControl component="fieldset" size="small" style={{marginLeft:.5+'rem' }}>          
                <FormLabel size="small" >Prioridad</FormLabel>        
                <RadioGroup  row aria-label="position"  defaultValue="top"
                    name="icon" value={icon} onChange={onChangeRad} size="small"
                >
                    {listIcono.map((elemnt)=>
                    <Box mr={2}>                        
                        <FormControlLabel value={elemnt.id.toString()}  label={ elemnt.cadena} 
                            style={{color:elemnt.Color}}  control={<Radio color="primary"  />} /> 
                        <img src={obtenIcono(elemnt.dir)}  style={{ width:"20px", marginBottom:"-6px"}}/>
                    </Box>                        
                    )}                                                
                </RadioGroup>                     
            </FormControl>   
          
            <FormControl size='small' style={{marginRight:".5rem",marginBottom:".5rem",marginTop:".25rem"}}>
                <InputLabel size='small' variant="outlined" id="tipo-label-nvo">Tipo</InputLabel>
                <Select size='small' variant="outlined" style={{width: '13rem'}}
                    label="Tipo" id="Tipo-select2" name="tipo"
                    value={tipo} onChange={onChangeTipo} fullWidth
                >
                    {listTpDet.map((aux, index) => {
                        return (
                        <MenuItem size='small' value={aux.id} key={aux.id}>
                            <em>{aux.cadena}</em>
                        </MenuItem>
                        )
                        }
                    )}
                </Select>
            </FormControl>    
            
            {tipo === 10? 
            <SelectList lista={listEstruc} value={tpEstructura} onChange={onChangeSlct} 
                etqVal={"Id"} etqId="Id" etqAlias={"Alias"} etiq={"Estructura"} tipo="outlined" 
                estilo={{marginLeft:1+'rem', minWidth:10+'rem'}} nameEtq={"tpEstructura"}
                /> 
            :null}
            {leyenda !=="" ?
                <Grid item xs={12}  >                    
                    <Alert severity={"info"} >
                        {leyenda} 
                    </Alert>
                </Grid>
            :null}
            { tipo === 10&& listSuperior.length !== 0 ?
               <Autocomplete
               id="sel-superior" size='small' style={{ width:'32rem',marginTop:".5rem", marginBottom:".5rem" }}
               onChange={(e,option)=>guardarSupr(option)}  value={Superior} name="Superior"             
               getOptionLabel={(option) => option.Cargo?`${option.Cargo} - ${option.Nom}`:""} 
               noOptionsText={"Sin coincidencias"} options={listSuperior}
               renderInput={(params) => (
                   <TextField {...params} size='small' label="Superior" variant="outlined" />
               )} 
           />
            : null 
            }            
            <Grid item xs={12} style={{}}>                
                <TextField id="nomNotif-Txt" name="nomNotif" label="Título" fullWidth size="small"
                    value={nomNotif} onChange={onChangeTxt} variant="outlined"/>                                        
            </Grid>
            <Grid item xs={12} style={{}}>
                <TextField id="descr-Txt" name="descr" label="Descripción" fullWidth size="small"
                    value={descr} onChange={onChangeAll} variant="outlined"
                    multiline maxRows={4}      inputProps= {{ maxLength: 1000 }}
                />                
            </Grid>                        
            <Grid item xs={12} style={{marginBottom:"1.5rem"}} >                              
                <TextField id="link-Txt" name="link" label="link" fullWidth size="small"
                    value={link} onChange={onChangeAll} variant="outlined"/>                        
            </Grid>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                {arraySubidos.length < 5 ? uploadFiles() : null}
                {arrayPreview.length !== 0 ? showImages() : null}
                {progresoEnvio > 0 ? (
                    <Progress progress={progresoEnvio}/>)
                : null}
            </Grid>
        </Grid>
    </Modal>);
};


export default ModalNvoEdit;
