import React from 'react'
import moment from 'moment';
import {Box} from "@material-ui/core";
const alturaCont = window.innerHeight<937 ? window.innerHeight-120 : window.innerHeight-100
const Reporte=({urlReport,informacion,nombre})=>{
    let today = new Date();                    
    let fec = moment(today);
    React.useEffect(()=>{
        let Stimulsoft = window.Stimulsoft;
        Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHl2Nbzg4HdTNm8R4+DL0SOlyjrr1Ca93XZRSF451sMrUQUbIC" + 
        "Rc4TZ6dJAire9aEpoaC1IiI6x5VLHaMglc1s2k4uRm4dUYTjqhd9tzwg51DplBBlocu7D5g5ObpQQ48TKP6bm0KtDs" + 
        "IvGW90HJ8m3O/KqEDnPXDYxuMiAVMZm7N1AsPVnk8wX2vhYjKVE8JJciqq6ves7LcM9CixolwKzMHJRyNLh/RL+0Rp" + 
        "/IZSOKcqneLtLhVtiYYXhWNvjKOjkUDvmzngVAuJ7WTtYx4bHfnjqcmOtnSpXX0KyPanw5zxMkdkeoWsU3u3hQTSOL" + 
        "9omRDSvCw4xMAdFVnsNI754ALmBQx0d17rjPlWbIEQ1nN0L2HE0S8bveNQUkWpyUDscl0MxOzoHyAJ7Bf/3DgmVpdE" + 
        "uVVeinsKXs6gsTIUyOVrMFro/cdNfb7NzgaY7GkRGLeGke+AGiCTyMs8jfaH5atn0U/EPb9ZeuMx1xCpgEbh+ROVii" + 
        "gsQlBiHxeeVRHD2Do/lqZXN4401UNbjOlg24";  
        Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("localization/es.xml", true);
        //Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("Roboto-Black.ttf");
        let report = Stimulsoft.Report.StiReport.createNewReport();
        report.loadFile(urlReport);

        let dataSet = new Stimulsoft.System.Data.DataSet("ReporteBitacora");
        dataSet.readJson(informacion);
        report.regData(dataSet.dataSetName, "", dataSet);
        let options = new Stimulsoft.Viewer.StiViewerOptions();

        options.toolbar.showOpenButton = false;          
        options.toolbar.showDesignButton = false;  
        options.toolbar.showAboutButton = false;
        options.toolbar.viewMode= Stimulsoft.Viewer.StiWebViewMode.Continuous    


        options.exports.showExportToHtml=false;
        options.exports.showExportToHtml5=false;
        options.exports.showExportToCsv=false;
        options.exports.showExportToJson=false;
        options.exports.showExportToText=false;
        options.exports.showExportToOpenDocumentWriter=false;
        options.exports.showExportToOpenDocumentCalc=false;        
        options.exports.showExportToPowerPoint=false;
        options.exports.showExportToImageSvg=false;
        options.exports.showExportToExcel2007=false;      
        options.exports.showExportToDocument=false;      
        options.appearance.showTooltipsHelp=false;
        options.exports.showExportToExcel2007 = false;
        //options.toolbar.showSendEmailButton = true;
        let viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);
        report.reportName=fec.format("YYMMDDHHmmss")+nombre
        /*viewer.onBeginExportReport = function (event) {        
            console.log("printing");            
        }*/
        viewer.report = report;
        viewer.onEmailReport = function (args) {

            // args.settings -  send email form
            
            // args.settings.email  -  email adress
            
            // args.settings.subject  -  email subject
            
            // args.settings.message  -  email message
            
             console.log(`Formato[${args.format}].email[${args.settings.email}.subject[${args.settings.subject}].message[${args.settings.message}].fileName[${args.fileName}].data[${args.data}]`)
            
            // args.format  -  export format - PDF, HTML, HTML 5, Excel2007, Word2007, CSV
            
            // args.fileName - report file name (name of attachement)
            
            // args.data  -  byte array with exported report file
            
            }
        viewer.renderHtml("viewerContent");
    },[informacion,fec,nombre,urlReport]);
    return(  
        <Box
        alignItems="center" justifyContent="center" style={{marginTop:'1em',height:alturaCont, overflowY:'scroll'}}>
                <div id="viewerContent" >Cargando datos...</div>   
        </Box>           
    )
}
export default Reporte
