import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,Polygon,Marker } from '@react-google-maps/api';
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
import CajaLeyenda from './CajaLeyenda';
  let arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
                    "#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

export default function MapaConsultaMarcador({poligonos,centro ,setCambiaMarcador,redimensionar,zoom,listMapProm,listApoyos,listRecorrido,auxTam}){  
  let altuTamMax=825
  let anchoTamMax=1535
  const [rutaPol, setRutaPol] = useState([]);
  const [autocomplete, setAutocomplete] = useState([]);  
  const [auxColores, setAuxColores] = useState(arreColores);
  const [alturaContenedor, setAlturaContenedor] = useState (
                                            (!redimensionar) ? (window.innerHeight<altuTamMax ? window.innerHeight-165: window.innerHeight - 200)
                                                             : (window.innerHeight<altuTamMax ? window.innerHeight-215: window.innerHeight - 250))
  const [anchoContenedor, setAnchoContenedor] = useState(
                                            (!redimensionar) ? window.innerWidth<anchoTamMax ? window.innerWidth-200 : window.innerWidth-300
                                                             : window.innerWidth<anchoTamMax ? window.innerWidth-510 : window.innerWidth-750)  
  const [leyendaMap, setLeyendaMap] = useState([]);
  const [tamDefinido, setTamDefinido] = useState([])   

  useEffect(()=>
  {  
    if (poligonos&&poligonos.length!==0)
    {      
      let ordenado=[]
      poligonos.forEach((poligono) => {
        let arr=[]
        if (poligono.gps) {
          poligono.gps.forEach((coorde)=>{
            let auxCoord=generaCoordenadaDinam(coorde.Lat, coorde.Lon) 
            arr.push(auxCoord)                 
          })
          ordenado.push({Id:poligono.Id,gps:arr,Color:poligono.Color?poligono.Color:""})  
        }
        
      })

      setRutaPol(ordenado);

      let auxNumSecc = poligonos.length
      if (auxNumSecc > arreColores.length) 
      {
        let auxArregloColor=[]

        for (let index = 0; index < auxNumSecc; index++) 
        { let nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)
          auxArregloColor[index] = arreColores[nivel]          
        } 
        setAuxColores(auxArregloColor)
      }          
    }

    setCambiaMarcador(true)   

    if (auxTam   ) {
      setTamDefinido({wdt:auxTam.wd, hgt:auxTam.hgt})
    }
    else{
      setTamDefinido([])
    }

  },[centro,poligonos]);


useEffect(()=>{
  window.addEventListener('resize', function() {
    if(!redimensionar){
        if(window.innerHeight<altuTamMax){
          setAlturaContenedor(window.innerHeight-165)
          }else{
              setAlturaContenedor(window.innerHeight-200)
          }
          if(window.innerWidth<anchoTamMax){
            setAnchoContenedor(window.innerWidth-200)
          }else{
            setAnchoContenedor(window.innerWidth-200)
          }
    }else{
      if(window.innerHeight<altuTamMax){
            setAlturaContenedor(window.innerHeight-200)
        }else{
            setAlturaContenedor(window.innerHeight-300)
        }

        if(window.innerWidth<anchoTamMax){
            setAnchoContenedor(window.innerWidth-510)
        }else{
            setAnchoContenedor(window.innerWidth-750)
        }
    }
});
},[alturaContenedor,anchoContenedor,anchoTamMax,altuTamMax,redimensionar])

  const onLoad =(respuesta)=> {
   // console.log('autocomplete: ', respuesta)

    //autocomplete = respuesta
    setAutocomplete(respuesta);
    console.log(autocomplete)
    //this.gps = autocomplete.getPlace().geometry.location
  }
 
  const options =(Color,numero)=> {    
    const auxiliar={  
    fillColor: Color.length!==0?Color: auxColores[numero],
    fillOpacity: 0.5,
    strokeColor: "#0097A7",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }

  const onLoadPolygono = marker => {
    //console.log('Poligono: ', marker)
  }  

  const formatoInfo=(info,auxCoord,valor)=>{    
    let auxArr=[]
    const txt=`Usuario: ${info.UsrNom}`
    const txt2=`Tel: ${info.UsrTel}`
    const txt3=`Secc:${info.SeccNum}  Tipo: ${info.TipoNom}`       
    const txt4=`Observ: ${info.Obsrv}`       
    const txt5="Dir: "+info.Dir              
        
    auxArr={id:info.Id, coordenada:auxCoord, texto:txt, texto2:txt2,
      texto3:txt3, texto4:txt4, texto5:txt5, valor:valor}    
  //  console.log(auxArr);   
    setLeyendaMap(auxArr)
    //return auxArr
  }

  return (//    "AIzaSyAw91KtccbYS3AN0Lex6799itZyB7VXjq0"
  <LoadScript googleMapsApiKey={process.env.REACT_APP_G_MAP_KEY} 
  libraries={["places"]}> 
    <GoogleMap
      id="searchbox-example" zoom={zoom} center={centro}
      mapContainerStyle={{ width: tamDefinido.length !==0 ? tamDefinido.wdt: anchoContenedor, height: tamDefinido.length !==0 ? tamDefinido.hgt : alturaContenedor }}                
    >   
    {leyendaMap.length!==0?
      <CajaLeyenda leyendaMap={leyendaMap} setLeyendaMap={setLeyendaMap} galeria={[]} />      
    :null }             
    { rutaPol!==null && rutaPol.length!==0?
      rutaPol.map((poligono,index) => 
      {
        return(
        <Polygon
          onLoad={onLoadPolygono} paths={poligono.gps}
          options={options(poligono.Color, index)}
        />)
      })      
      :null
    }       
     {listMapProm && listMapProm.length!==0 ?
        listMapProm.map((perfil,index)=>{
          const auxCoord=generaCoordenadaDinam(perfil.Lat, perfil.Lon) 
          //console.log(auxCoord);
          const aviso=perfil.Gps===0?"\nSin Coordenadas":""
          const icono=perfil.Gps=== 1 ? (perfil.StatusId===2 ? "marcador-valido.svg":(perfil.StatusId===1? "marcador-pendiente.svg":"marcador-rechazado.svg"))
          :( perfil.StatusId===2 ? "marcador-valid-snc.svg":(perfil.StatusId===1? "marcador-pend-snc.svg":"marcador-rech-snc.svg") )
          return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={`${perfil.Nom} \n${perfil.Tel} ${aviso}`}   key={index}
              position={ auxCoord}  
              icon= {icono}                   
            />)
        })
      :null}
      {listApoyos && listApoyos.length!==0 ?
        listApoyos.map((perfil,index)=>{
          const auxCoord=generaCoordenadaDinam(perfil.Lat, perfil.Lon)                     
          const icono="marcador-militante.svg"       
          return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={`${perfil.Obsrv} \n${perfil.Dir} `}   key={index}
              position={ auxCoord}  
              icon= {icono}                   
            />)
        })
      :null}
      {listRecorrido && listRecorrido.length!==0 ?
        listRecorrido.map((recorr,index)=>{
          const auxCoord=generaCoordenadaDinam(recorr.Lat, recorr.Lon)                     
          const icono="marca_recorrido.svg"       
          return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={`${recorr.TipoNom} \n${recorr.Obsrv} `}   key={index}
              position={ auxCoord}  onClick={()=>formatoInfo(recorr,auxCoord,true)} 
              icon= {icono}                   
            />)
        })
      :null}
    </GoogleMap>
  </LoadScript>);
}
 