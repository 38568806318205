import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NumberFormat from 'react-number-format';
const useStyles = makeStyles({
    estiloCard:{
      width: '28rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: "12rem",
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '27.5rem',
      maxHeight: '13.8rem',
      minHeight: "12rem",
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

 

export default function TablaTotal({listAv,registro, setRegistro,}){    
  const classes = useStyles();      
  const [datos,setDatos] = useState([]);     
  const [totalGen,setTotalGen] = useState([]);     
  const auxCol=process.env.REACT_APP_Fondo_Color  
  useEffect(()=>{    
    
    if (listAv.length!==0 ) 
    { let aux={totH:0, GenX:0, totM:0, totX:0, }
      let auxH=0,auxM=0,auxX=0
      listAv.forEach(element => {
        if (element!=="") {
          
          //auxH= auxH + parseInt(element.TotH)
          //auxM=0,auxX
        aux={ totH: aux.totH + parseInt( element.TotH),
          totM: aux.totM + parseInt(element.TotM),
          totX:aux.totX +  parseInt(element.TotX),
          //avGen: aux.avGen + element.Avc,
          //MetaGen: aux.MetaGen + element.Meta
        }
      }
      });  
      setDatos(listAv)  
      setTotalGen(aux)
      
    }
    else
    { setDatos([])
    }  
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAv])          

  const onChangeTable =(regis)=>{       
    setRegistro(regis)                           
  }

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  const colorStatus=(status)=>{
    let stdColor=""
    if (status<3) 
    { stdColor="#E91E63"      
    }
    else
    { if (status<5) 
      {
        stdColor="#f07111"      
      }
      else
      {
        if (status<7) 
        { stdColor="#FBBE06"      
        }
        else
        { stdColor="#19A11E"                        
        }         
      } 
    }
    return(
    <Box display={"flex"}  justifyContent="flex-end" style={{textAlign:"right",alignItems:"center",fontSize:"12px"}}>            
      {`${status}`}
      <FiberManualRecordIcon fontSize="small" style={{color:stdColor,}}/>      
    </Box>)
  }  

  const primerformato = str => {
    return str.toLowerCase().replace(/\b[a-z]/g, c => c.toUpperCase())
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >    
        { totalGen.length!==0 ?
        <TableRow>
         {/*  <CelTitl index={1} titulo={ "Colonia" } aline={"left"} estilo={{fontSize:"13px"}} />        */}  
          <CelTitl index={2} titulo={"Sección " } aline={"center"} estilo={{fontSize:"13px"}} />                                    
          {/* <CelTitl index={4} titulo={
            <span  style={{fontSize:"12px"}}>Total <br/><strong>{listAv[0].total[0].Total}</strong></span> } aline={"center"} />          */}
          <CelTitl index={5} titulo={<span style={{fontSize:"12px"}}>Hombre <br/><strong> 
            <NumberFormat value={totalGen.totH} displayType={'text'}  thousandSeparator={true}/></strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />         
          <CelTitl index={6} titulo={<span style={{fontSize:"12px"}}>Mujer <br/><strong>
          <NumberFormat value={totalGen.totM} displayType={'text'}  thousandSeparator={true}/></strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />         
          <CelTitl index={7} titulo={<span style={{fontSize:"12px"}}>No Binario <br/> <strong>{totalGen.totX}</strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />                             
        </TableRow>
        :null }                   
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.Id === elemnto.Id ?true:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => onChangeTable(elemnto) }  
        >   
          {/* celda("left", {width:"4rem", fontSize:"12px"},primerformato(elemnto.Col) ,index+1)  */}        
          {celda("left", {width:"10rem", fontSize:"12px"}, primerformato(elemnto.Nom) ,index+2) }          
          {/* celda("right", {width:"3rem", fontSize:"12px"}, colorStatus(elemnto.Total),index+4)  */}
          {celda("right", {width:"2.4rem", fontSize:"12px"}, <NumberFormat value={elemnto.TotH} displayType={'text'}  thousandSeparator={true}/> ,index+5) }
          {celda("right", {width:"2.4rem", fontSize:"12px"}, <NumberFormat value={elemnto.TotM} displayType={'text'}  thousandSeparator={true}/> ,index+6) }
          {celda("right", {width:"3.4rem", fontSize:"12px",paddingRight:".3rem"}, <NumberFormat value={elemnto.TotX} displayType={'text'}  thousandSeparator={true}/> ,index+7) }     
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
                       
  const llamada= datos.length !== 0 ?(TablaEstr(datos)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
                                                                                                                                           
  return (
  <Box display={'flex'} flexDirection="row" style={{height: '15rem' }}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-180}}>                        
      {/* <Typography variant="body1" component="body1"></Typography>  */}
      {llamada}   
    </Card>

  </Box>                    
  );
}